<template>
	<div v-if="loaded">
		<div>
			Платежка:
			<nav>
				<a v-for="(p, i) in payin" :key="'pin_' + i" :class="{'router-link-exact-active': psid == p.id}" href="#" @click.prevent="psid = p.id">{{p.name}}</a>
			</nav>
			<div v-if="psid">
				<input v-if="psid" type="number" v-model="sum" placeholder="Сумма" title="Сумма"/> <button @click.prevent="payIn">Пополнить</button>
			</div>
		</div>
		<hr/>
		<div>
			Транзакции:
			<table>
				<tr>
					<th>ID/PID</th>
					<th>Тип</th>
					<th>Сумма</th>
					<th>Итог</th>
					<th>Детали</th>
					<th>Статус</th>
					<th>Дата</th>
				</tr>
				<tr v-if="!trans"><td colspan="6">Загрузка</td></tr>
				<tr v-else-if="!trans.length"><td colspan="6">Нет транзакций</td></tr>
				<template v-else>
					<tr v-for="(t, i) in transs" :key="'trans_' + i">
						<td><b>{{t.id}}</b>/{{t.pid}}</td>
						<td>{{t.type}}</td>
						<td>{{parseFloat(t.sum).toFixed(2)}} {{t.currency}}</td>
						<td>{{parseFloat(t.balance_result).toFixed(2)}} {{t.currency}}</td>
						<td>
							<div class="fields">
								<span v-if="t.type == 'payin'">{{getPayName(t) || (t.pid ? 'С партнерского баланса' : 'Система')}}</span>
								<template v-if="t.fields">
									<span v-for="(v, k) in t.fields" :key="'trans_' + i + '_f_' + k" :title="k">{{v}}</span>
								</template>
								<span v-if="t.desc">{{t.desc}}</span>
							</div>
						</td>
						<td>{{t.status}}</td>
						<td>{{new Date(t.ts).toISOString()}}</td>
					</tr>
				</template>
			</table>
		</div>
	</div>
</template>

<script>

export default {
	components: { },
	data: () => ({
		loaded: false,
		trans: null,
		sum: 1000,
		psid: 0
	}),
	computed: {
		transs() {
			return this.trans ? this.trans.slice().sort((a, b) => b.id - a.id) : [];
		},
		payin() {
			return (this.glob.payin || []).filter(el => el.active && el.currency == this.glob.currency);
		}
	},
	methods: {
		payIn() {
			const ps = this.payin.find(el => el.id == this.psid);
			const sum = parseFloat(parseFloat(this.sum).toFixed(2));
			if(sum < ps.min) return alert('Сумма слишком мелкая для этой платежки');
			if(sum > ps.max) return alert('Сумма слишком большая для этой платежки');
			this.spapi.makePayLink(ps.id, sum, document.location.href).then(r => {
				document.location = r.url;
			}).catch(e => {
				console.error(e);
				alert('Что-то пошло не так');
			});
		},
		getPayName(t) {
			if(t.type == 'payin') return (this.glob.payin.find(el => el.id == t.psid) || {}).name || t.psid;
			return null;
		},
		getTrans(id) {
			this.spapi.getMainTransactions(id ? {id} : null).then(r => {
				if(!this.trans) {
					this.trans = r.transactions;
				} else {
					r.transactions.forEach(el => {
						const ex = this.trans.find(t => t.id == el.id);
						if(ex && ex.uts < el.uts) Object.assign(ex, el);
						else if(!ex) this.trans.push(el);
					});
				}
			}).catch(e => {
				console.error(e);
			})
		},
		onTrans(d) {
			this.getTrans(d.tid);
		}
	},
	created() {
		if(!this.glob.profile) {
			this.$router.push('/auth');
			return;
		}
		this.spapi.on('maintransaction', this.onTrans);
		this.getTrans();
		this.loaded = true;
	},
	beforeDestroy() {
		this.spapi.off('maintransaction', this.onTrans);
	}
}
</script>
