<template>
	<div v-if="loaded">
		<div v-if="f.auth.tgbotauth">
			Подтвержите вход через телегу (туда должно прийти сообщение с кнопкой для авторизации)
		</div>
		<div v-else>
			<form @submit.prevent="login">
				Регистрация или вход:
				<input required type="email" title="E-Mail" placeholder="email@example.com" v-model="f.auth.email"/>
				<input v-if="f.auth.needpass" title="Пароль" placeholder="Пароль" type="password" v-model="f.auth.pass"/>
				<button type="submit">Вход</button>
			</form>
		</div>
		<hr>
		<form @submit.prevent="restore">
			Сброс пароля:
			<input required type="email" title="E-Mail" placeholder="email@example.com" v-model="f.restore.email"/>
			<button type="submit">Сбросить</button>
		</form>
	</div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
	data: () => ({
		loaded: false,
		f: { 
			auth: {
				email: '',
				pass: '',
				needpass: false,
				tgbotauth: false,
				tgbotauthcode: null,
			},
			restore: {
				email: ''
			}
		},
	}),
	methods: {
		login() {
			this.spapi.login(this.f.auth.email, this.f.auth.pass, {reg: 2, tgbotauthcode: this.f.auth.tgbotauthcode}).then(r => {
				if(r.status == 'needpass') {
					this.f.auth.needpass = true;
				}
				if(r.status == 'tgbotauth') {
					this.f.auth.tgbotauth = true;
				}
				if(r.status == 'bad') {
					alert('Неправилный e-mail или пароль.');
				}
				if(r.status == 'newuser') {
					alert('Вы зарегистрированы. Новый пароль: ' + r.newpass);
					this.$router.push('/');
				}
				if(r.status == 'ok') {
					this.$router.push('/');
				}
			}).catch(e => {
				console.error(e);
				alert('Какая-то ошибка. Повторите запрос позднее.');
			});
		},
		restore() {
			this.spapi.restore(this.f.restore.email).then(r => {
				if(r.status == 'bad') {
					alert('Неправилный e-mail.');
				}
				if(r.status == 'ok') {
					alert('Новый пароль отправлен на почту.');
				}
			}).catch(e => {
				console.error(e);
				alert('Какая-то ошибка. Повторите запрос позднее.');
			});
		},
		onTG(d) {
			if(d.code) {
				if(!this.f.auth.tgbotauthcode) {
					this.f.auth.tgbotauthcode = d.code;
					if(this.f.auth.email && this.f.auth.pass) this.login();
				}
			}
		}
	},
	created() {
		if(this.glob.profile) {
			this.$router.push('/');
			return;
		}
		if(this.$route.query.email) {
			this.f.auth.email = this.f.restore.email = this.$route.query.email;
			this.f.auth.needpass = true;
		}
		this.spapi.on('tgbotauth', this.onTG);
		this.loaded = true;
	},
	beforeDestroy() {
		this.spapi.off(this.onTG);
	}


}
</script>
