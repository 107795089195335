<template>
	<div v-if="loaded" class="home">
		<partners-refs-view/>
		<partners-bal-view/>
	</div>
</template>

<script>

import PartnersRefsView from './PartnersRefsView.vue'
import PartnersBalView from './PartnersBalView.vue'

export default {
	components: { PartnersRefsView, PartnersBalView },
	data: () => ({
		loaded: false,
	}),
	created() {
		if(!this.glob.profile) {
			this.$router.push('/auth');
			return;
		}
		this.loaded = true;
	}
}
</script>
