<template>
	<div>
		<div v-if="balances.length">
			<hr>
			<div>
				Партнерский баланс: <span v-for="(v, i) in balances" :key="'pb' + i"><b>{{v[0]}} {{v[1]}}</b><template v-if="i < balances.length - 1"> / </template></span>
			</div>
		</div>
		<div v-if="balances.length">
			<hr>
			<form @submit.prevent="withdraw">
				Вывод: 
				<select v-model="wd.curr">
					<option v-for="i in balances" :key="'wd_curr_' + i[1]" :value="i[1]">{{i[1]}}</option>
				</select>
				<select v-if="wd_methods.length" v-model="wd.method">
					<option v-for="(i, ii) in wd_methods" :key="'wd_method_' + ii" :value="i[0]">{{i[1]}}</option>
				</select>
				<input v-if="wd.method" type="text" v-model="wd.sum" placeholder="сумма"/>
				<input v-for="i in wd_fields" :key="'wd_k_' + i.k" type="text" v-model="wd.fields[i.k]" :title="i.name" :pattern="i.regexp" :placeholder="i.example"/>
				<button type="submit" :disabled="!wd_enabled">Вывести</button>
			</form>
		</div>
		<hr/>
		<div>
			Транзакции:
			<table>
				<tr>
					<th>ID/PID</th>
					<th>Тип</th>
					<th>Сумма</th>
					<th>Детали</th>
					<th>Статус</th>
					<th>Дата</th>
				</tr>
				<tr v-if="!trans"><td colspan="6">Загрузка</td></tr>
				<tr v-else-if="!trans.length"><td colspan="6">Нет транзакций</td></tr>
				<template v-else>
					<tr v-for="(t, i) in transs" :key="'trans_' + i">
						<td><b>{{t.id}}</b>/{{t.pid}}</td>
						<td>{{t.type}}</td>
						<td>{{parseFloat(t.sum).toFixed(2)}} {{t.currency}}</td>
						<td>
							<div class="fields">
								<span v-if="t.type == 'withdraw'">{{getPayName(t) || (t.pid ? 'На основной баланс' : 'Система')}}</span>
								<template v-if="t.fields">
									<span v-for="(v, k) in t.fields" :key="'trans_' + i + '_f_' + k" :title="k">{{v}}</span>
								</template>
								<span v-if="t.desc">{{t.desc}}</span>
							</div>
						</td>
						<td>{{t.status}}</td>
						<td>{{new Date(t.ts).toISOString()}}</td>
					</tr>
				</template>
			</table>
		</div>
	</div>
</template>

<script>

export default {
	data: () => ({
		trans: null,
		wd: {
			curr: '',
			method: '',
			sum: '',
			fields: {}
		}
	}),
	computed: {
		transs() {
			return this.trans ? this.trans.slice().sort((a, b) => b.id - a.id) : [];
		},
		wd_currentPS() {
			return this.wd.method > 0 ? this.glob.payout.find(el => el.id == this.wd.method) : null;
		},
		wd_min() {
			return this.wd_currentPS && this.wd_currentPS.min || 0;
		},
		wd_max() {
			return this.wd_currentPS && this.wd_currentPS.max || 0;
		},
		wd_enabled() {
			if(!this.wd.curr || !this.wd.method || !this.wd.sum) return false;
			const sum = parseFloat(String(this.wd.sum).replace(',', '.'));
			if(sum > this.glob.profile.partner_balance[this.wd.curr]) return false;
			return true;
		},
		wd_fields() {
			if(!this.wd.curr || this.wd.method < 1) return [];
			const ps = this.glob.payout.find(el => el.id == this.wd.method);
			if(!ps) return [];
			return Object.keys(ps.fields).map(el => Object.assign({k: el}, ps.fields[el]));
		},
		wd_methods() {
			if(!this.wd.curr) return [];
			const out = [[-1, 'На основной баланс']].concat(this.glob.payout.filter(el => el.active && el.currency == this.wd.curr).map(el => ([el.id, el.name])));
			return out;
		},
		user() {
			return this.glob.profile;
		},
		balances() {
			const out = [];
			const d = this.user.partner_balance;
			for(const k in d) {
				if(d[k] > 0) {
					out.push([parseFloat(d[k]).toFixed(2), k]);
				}
			}
			return out;
		},
	},
	watch: {
		'wd.curr'() {
			this.wd.method = '';
			this.wd.fields = {}
		},
		'wd.method'() {
			this.wd.fields = {}
		}
	},
	methods: {
		withdraw() {
			if(this.wd.method == -1) {
				this.spapi.movePartnerBalance(this.wd.sum, this.wd.curr).then(r => {
					if(r.status == 'ok') {
						alert('Выведено');
					} else if(r.status == 'nomoney') {
						alert('Нет денег');
					} else {
						alert('Что-то поло не так.');
						console.log(r);
					}
				}).catch(e => {
					console.error(e);
					alert('Что-то поло не так.');
				});
			} else {
				const ps = this.wd_currentPS;
				if(!ps || ps.currency != this.wd.curr) return alert('Что-то не так');
				const sum = this.wd.sum;
				if(sum < ps.min) return alert('Слишком мелкая сумма');
				if(sum > ps.max) return alert('Слишком большая сумма');
				const fields = {}
				for(const k in ps.fields) {
					fields[k] = String(this.wd.fields[k] || '');
					if(ps.fields[k].regexp && !(new RegExp(ps.fields[k].regexp)).test(fields[k])) return alert(`Неправильный формат: ${ps.fields[k].name}`);
				}
				this.spapi.withdrawPartnerBalance(ps.id, sum, fields).then(r => {
					if(r.status == 'ok') {
						alert('Выведено');
					} else if(r.status == 'nomoney') {
						alert('Нет денег');
					} else {
						alert('Что-то поло не так.');
						console.log(r);
					}
				}).catch(e => {
					console.error(e);
					alert('Что-то поло не так.');
				});
			}
		},
		getPayName(t) {
			if(t.type == 'withdraw') return (this.glob.payout.find(el => el.id == t.psid) || {}).name || t.psid;
			return null;
		},
		getTrans(id) {
			this.spapi.getPartnerTransactions(id ? {id} : null).then(r => {
				if(!this.trans) {
					this.trans = r.transactions;
				} else {
					r.transactions.forEach(el => {
						const ex = this.trans.find(t => t.id == el.id);
						if(ex && ex.uts < el.uts) Object.assign(ex, el);
						else if(!ex) this.trans.push(el);
					});
				}
			}).catch(e => {
				console.error(e);
			})
		},
		onTrans(d) {
			this.getTrans(d.tid);
		}
	},
	created() {
		this.spapi.on('partnertransaction', this.onTrans);
		this.getTrans();
	},
	beforeDestroy() {
		this.spapi.off('partnertransaction', this.onTrans);
	}
}
</script>
