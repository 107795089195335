import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import OptView from '../views/OptView.vue'
import AuthView from '../views/AuthView.vue'
import LogoutView from '../views/LogoutView.vue'
import PartnersView from '../views/PartnersView.vue'
import AccountView from '../views/AccountView.vue'
import OrdersView from '../views/OrdersView.vue'
import BalanceView from '../views/BalanceView.vue'
import ProxiesView from '../views/ProxiesView.vue'
import SupportView from '../views/SupportView.vue'
import FeedbackView from '../views/FeedbackView.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		component: HomeView
	},
	{
		path: '/opt',
		component: OptView
	},
	{
		path: '/support',
		component: SupportView,
	},
	{
		path: '/feedback',
		component: FeedbackView,
	},
	{
		path: '/feedback/:dialog',
		component: FeedbackView,
	},
	{
		path: '/auth',
		component: AuthView
	},
	{
		path: '/balance',
		component: BalanceView
	},
	{
		path: '/proxies',
		component: ProxiesView
	},
	{
		path: '/partners',
		component: PartnersView
	},
	{
		path: '/orders',
		component: OrdersView
	},
	{
		path: '/account',
		component: AccountView
	},
	{
		path: '/logout',
		component: LogoutView
	},
	{
		path: '*',
		redirect: '/'
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
