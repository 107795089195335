<template>
	<div>
		<div>
			Ссылка: <a :href="partnerurl" target="_blank">{{partnerurl}}</a> / Купон: <b v-if="user.partner_coupon_code">{{user.partner_coupon_code}}</b><a href="#" @click.prevent="getCoupon" v-else>Создать</a>
		</div>
		<div v-if="incomes.length">
			<hr>
			<div>
				Доход: <span v-for="(v, i) in incomes" :key="'pi' + i"><b>{{v[0]}} {{v[1]}}</b><template v-if="i < incomes.length - 1"> / </template></span>
			</div>
		</div>
		<hr>
		<div>
			Посетители: <b>{{user.partner_visits}}</b> / Регистрации: <b>{{user.partner_regs}}</b>
		</div>
		<hr>
		Рефералы:
		<table>
			<tr>
				<th>E-Mail</th>
				<th>Прибыль</th>
				<th>Дата регистрации</th>
				<th>Последняя покупка</th>
			</tr>
			<tr v-if="!partners"><td colspan="4">Загрузка</td></tr>
			<tr v-else-if="!partners.length"><td colspan="4">Нет партнеров</td></tr>
			<template v-else>
				<tr v-for="(p, i) in partnerss" :key="'plist_' + i">
					<td>{{p.email}}</td>
					<td>
						<div v-for="(v, k) in p.balance_up" :key="'plist_' + i + '_' + k"><b v-if="v">{{parseFloat(v).toFixed(2)}} {{k}}</b></div>
					</td>
					<td>{{new Date(p.reg.ts).toISOString()}}</td>
					<td>{{p.lastbuy ? new Date(p.lastbuy).toISOString() : '-'}}</td>
				</tr>
			</template>
		</table>
	</div>
</template>

<script>

export default {
	data: () => ({
		partners: null,
	}),
	computed: {
		partnerss() {
			return this.partners ? this.partners.slice().sort((a, b) => b.id - a.id) : [];
		},
		user() {
			return this.glob.profile;
		},
		partnerurl() {
			return location.protocol + '//' + location.host + '/?ref=' + this.user.id;
		},
		incomes() {
			const out = [];
			const d = this.user.partner_income;
			for(const k in d) {
				if(d[k] > 0) {
					out.push([parseFloat(d[k]).toFixed(2), k]);
				}
			}
			return out;
		}
	},
	methods: {
		getCoupon() {
			this.spapi.getPartnerCoupon().then(r => {
				console.log(r.coupon.code);
			}).catch(e => {
				console.error(e);
			})
		},
		getPartners(id) {
			this.spapi.getPartners(id ? {id} : null).then(r => {
				if(!id || !this.partners) {
					this.partners = r.partners;
				} else {
					r.partners.forEach(el => {
						const ex = this.partners.find(t => t.id == el.id);
						if(ex) Object.assign(ex, el);
						else this.partners.push(el);
					});
				}
			}).catch(e => {
				console.error(e);
			})
		},
		onPartner(d) {
			this.getPartners(d.id);
		}
	},
	created() {
		this.spapi.on('newpartner', this.onPartner);
		this.getPartners();
	},
	beforeDestroy() {
		this.spapi.off('newpartner', this.onPartner);
	}
}
</script>
