import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false;

Vue.mixin({
	data: () => ({
		spapi_dicts_url: 'https://keyval.spapi.net/get?fmt=deepobj&key=dicts',
		spapi: window.SPSAPI,
		glob: window.SPSAPI.data
	})
});

new Vue({
	router,
	render: h => h(App)
}).$mount('#app');
