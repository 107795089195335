<template>
	<div id="app" v-if="loaded">
		<div>
			<nav-view/>
			<hr/>
		</div>
		<router-view />
	</div>
</template>

<script>

import NavView from './views/NavView.vue'

export default {
	components: {
		NavView
	},
	data: () => ({
		loaded: false
	}),
	methods: {
	},
	created() {
		fetch(this.spapi_dicts_url).then(r => r.json()).then(dicts => {
			this.spapi.data.dicts = dicts.dicts;
			if(!this.spapi.getDictData) this.spapi.getDictData = function(group, id, key='name') {
				const adict = (this.data.dicts[this.data.lang] || {})[group];
				if(!adict) return id;
				const k_id = adict.keys.indexOf(typeof id === 'number' ? 'id' : 'txtid');
				const k_name = adict.keys.indexOf(key);
				if(k_name < 0 || k_id < 0) return id;
				const d = adict.data.find(el => el[k_id] === id);
				return d && d[k_name] || id;
			}
			if(!this.spapi.getDictName) this.spapi.getDictName = function(group, id) {
				return this.getDictData(group, id, 'name');
			}
			return this.spapi.init();
		}).then(r => {
			if(r) {
				this.loaded = true;
			}
		});
	},
}
</script>

<style>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}

.fields {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
}

table { border-spacing: 0; border-collapse: collapse; }
table tr:not(:last-child) { border-bottom: 1px solid #ddd; }
table tr:hover { background-color: #f5f5f5; }
table th,
table td { padding: 5px 10px; text-align: left; vertical-align: top; }

nav {
	padding: 20px;
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	justify-content: center;
}

nav a {
	display: block;
	text-decoration: none;
	color: #2c3e50;
}

nav a.router-link-exact-active {
	color: #42b983;
}
</style>
