<template>
	<div v-if="loaded" class="home">
		<div>
			<form @submit.prevent="send">
				<div v-if="!code">
					<input type="email" v-model="email" placeholder="e-mail" title="e-mail" style="width: 100%"/>
					<br/><br/>
				</div>
				<textarea rows="4" style="width: 100%" v-model="msg" placeholder="Сообщение"></textarea>
				<br/>
				<br/>
				<button type="submit">Send</button>
				<br/><br/>
				<div v-if="status == 'open'"><button type="button" @click.prevent="close">Close</button></div>
				<div v-else>Dialog inactive. For activate send message.</div>
			</form>
		</div>
		<hr/>
		<div v-if="messages && messages.length">
			<div v-for="(m, i) in smessages" :key="'m_' + i">
				<div><b>{{getAuthorName(m)}}</b> {{new Date(m.ts).toISOString()}}</div>
				<div v-if="m.files && m.files.length">
					<div v-for="(f, ii) in m.files" :key="'m_' + i + '_f_' + ii">
						<div v-if="f.mime.startsWith('image/')" style="width: 100px; height: 100px"><img style="max-width: 100%; max-height: 100%;" :src="['data:', f.mime, ';base64,', f.data].join('')"/></div>
						<div v-else><a :download="f.name" :href="['data:application/octet-stream;base64,', f.data].join('')" :title="f.size">{{f.name}}</a></div>
					</div>
				</div>
				<div v-if="m.msg" v-html="uhtml(m.msg)"></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data: () => ({
		msg: '',
		email: '',
		loaded: false,
		managers: null,
		status: null,
		messages: [],
		code: null,
	}),
	computed: {
		smessages() {
			return (this.messages || []).slice().sort((a, b) => b.id - a.id);
		}
	},
	methods: {
		close() {
			this.spapi.supportDialogClose(this.code).then(r => {
				this.status = r.status;
			}).catch(e => {
				console.error(e);
			});
		},
		getAuthorName(m) {
			if(m.utype == 'user') return 'Вы';
			else if(m.utype == 'notice') return 'Оповещение';
			const ex = this.managers.find(el => el.id == m.uid);
			return ex && ex.name || 'Manager';
		},
		uhtml(text) {
			return this.spapi.supportTextToHtml(text);
		},
		newMessage(m) {
			const ex = this.messages.find(el => el.id === m.id);
			if(ex) Object.assign(ex, m);
			else this.messages.push(m);
		},
		onNewMessage(d) {
			if(!this.code) return;
			this.spapi.supportDialog({id: d.id, code: this.code}).then(r => {
				this.status = r.status;
				r.messages.forEach(this.newMessage);
			}).catch(e => {
				console.error(e);
			})
		},
		send() {
			if(!this.msg) return;
			if(!this.code && !this.email) return;
			if(!this.code) { // Создаем новый диалог
				if(!/^.+@.+\..+$/.test(this.email)) return;
				this.spapi.supportGuestCreate(this.email, this.msg).then(r => {
					this.newMessage(r.message);
					this.status = r.status;
					this.code = r.code;
					this.msg = '';
					this.$router.replace('/feedback/' + this.code);
				}).catch(e => {
					console.error(e);
					alert('Какая-то ошибка. Повторите запрос позднее.');
				});
			} else { // Продолжаем старый диалог
				this.spapi.supportGuestSend(this.code, this.msg).then(r => {
					this.newMessage(r.message);
					this.status = r.status;
					this.code = r.code;
					this.msg = '';
				}).catch(e => {
					console.error(e);
					alert('Какая-то ошибка. Повторите запрос позднее.');
				});
			}
		},
		load() {
			const code = this.$route.params.dialog || null;
			let n = code ? 2 : 1;
			this.spapi.supportManagers().then(r => {
				this.managers = r;
				if(--n == 0) this.loaded = true;
			});
			if(code) {
				this.spapi.supportDialog({code}).then(r => {
					this.messages = r.messages;
					this.status = r.status;
					this.code = r.code;
				}).catch(() => {
					// Если диалог не найден по коду
					this.$router.replace('/feedback');
				}).finally(() => {
					if(--n == 0) this.loaded = true;
				});
			}
		},
	},
	created() {
		if(this.glob.profile) {
			this.$router.push('/support');
			return;
		}
		this.spapi.on('support_message', this.onNewMessage);
		this.load();
	},
	beforeDestroy() {
		this.spapi.off('support_message', this.onNewMessage);
	}
}
</script>
