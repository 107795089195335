<template>
	<div v-if="loaded"></div>
</template>

<script>
export default {
	data: () => ({
		loaded: false
	}),
	created() {
		this.logout();
	},
	methods: {
		logout() {
			this.spapi.logout().then(() => {
				this.glob.profile = null;
				this.$router.push('/');
			}).catch(e => {
				console.error(e);
				alert('Какая-то ошибка. Повторите запрос позднее.');
			});
		}
	}
}
</script>
