<template>
	<div v-if="loaded">
		<div>
			Прокси:
			<table>
				<tr>
					<th>ID/OID</th>
					<th>Коннект</th>
					<th>Истекает</th>
					<th>Кнопки</th>
					<th>Коммент</th>
				</tr>
				<tr v-if="!proxies"><td colspan="6">Загрузка</td></tr>
				<tr v-else-if="!proxies.length"><td colspan="6">Нет прокси</td></tr>
				<template v-else>
					<tr v-for="(o, i) in proxiess" :key="'o_' + i">
						<td><b>{{o.id}}</b>/{{o.xoid}}</td>
						<td>{{o.proto}}://{{o.user}}:{{o.pass}}@{{o.host}}:{{o.port}}</td>
						<td>{{new Date(o.expires).toISOString()}}</td>
						<td>
							<div v-if="o.busy">Обрабатывается...</div>
							<div v-else>
								<div v-if="o.action.autoprolong && o.expires_prolong - glob.disable_autoprolong_ms > Date.now()"><label><input type="checkbox" @click.prevent="configProxy(o, 'autoprolong', !o.autoprolong)" :checked="o.autoprolong"> Автопродление</label></div>
								<div v-if="o.action.prolong && o.expires_prolong - glob.disable_update_ms > Date.now()">
									Продлить
									<span v-for="(p, i1) in o.action.prolong" :key="'px_' + i + '_pl_' + i1">
										/ <a @click.prevent="prolongProxy(o, p)" href="#">{{p}}</a>
									</span>
								</div>
								<div v-if="o.expires - glob.disable_update_ms > Date.now()">
									<div v-if="o.action.setproto">
										Протокол
										<span v-for="(p, i1) in o.action.setproto" :key="'px_' + i + '_pt_' + i1">
											/ 
											<b v-if="o.proto == p">{{o.proto}}</b>
											<a v-else @click.prevent="configProxy(o, 'proto', p)" href="#">{{p}}</a>
										</span>
									</div>
									<div v-if="o.action.setautorotate">
										Авторотация
										<template v-if="Array.isArray(o.action.setautorotate)">
											<span v-for="(p, i1) in o.action.setautorotate" :key="'px_' + i + '_ar_' + i1">
												/ 
												<b v-if="o.autorotate == p">{{o.autorotate}}</b>
												<a v-else @click.prevent="configProxy(o, 'autorotate', p)" href="#">{{p}}</a>
											</span>
										</template>
										<template v-else>
											<input type="number" v-model="o.autorotate" step="1" :min="o.action.setautorotate.min" :max="o.action.setautorotate.max"><button @click.prevent="configProxy(o, 'autorotate', o.autorotate)">Сменить</button>
										</template>
									</div>
									<div v-if="o.action.setfingerprint">
										Отпечаток
										<span v-for="(p, i1) in o.action.setfingerprint" :key="'px_' + i + '_fp_' + i1">
											/ 
											<b v-if="o.fingerprint == p">{{o.fingerprint}}</b>
											<a v-else @click.prevent="configProxy(o, 'fingerprint', p)" href="#">{{p}}</a>
										</span>
									</div>
									<div v-if="o.action.rotate">
										<a @click.prevent="proxyRotate(o)" href="#">Сменить IP (ротация)</a>
									</div>
								</div>
							</div>
						</td>
						<td>
							<input type="text" v-model="o.desc"/><button type="button" @click.prevent="configProxy(o, 'desc', o.desc || '')">Save</button>
						</td>
					</tr>
				</template>
			</table>
		</div>
	</div>
</template>

<script>
export default {
	data: () => ({
		loaded: false,
		proxies: null,
		orders: null
	}),
	computed: {
		proxiess() {
			return this.proxies ? this.proxies.slice().sort((a, b) => b.created - a.created) : [];
		}
	},
	methods: {
		proxyRotate(o) {
			o.busy = true;
			this.spapi.proxyRotate(o.id).then(r => {
				if(r.status != 'ok') throw new Error('Bad status');
			}).catch(e => {
				alert('Что-то пошло не так');
				console.error(e);
			});
		},
		configProxy(o, param, val) {
			o.busy = true;
			this.spapi.proxyConfig(o.id, param, val).then(r => {
				if(r.status != 'ok') throw new Error('Bad status');
			}).catch(e => {
				alert('Что-то пошло не так');
				console.error(e);
			});
		},
		async prolongProxy(px, period) {
			px.busy = true;
			try {
				const r = await this.spapi.prolong(px.id, period);
				if(r.status == 'ok') {
					alert('Продление оформлено');
					return;
				}
				else if(r.status == 'nomoney') {
					const ps = this.glob.payin.find(el => el.active && el.currency == this.glob.currency); // Первая подходящая платежка (на продакшене нужно выбирать платежку)
					if(r.addbalance < ps.min) return alert(`Слишком мелкая сумма для пополнения, пополните баланс на сумму от ${ps.min} ${ps.currency} и повторите заказ`);
					else if(r.addbalance > ps.max) return alert(`Слишком большая сумма для одноразового пополнения, пополните баланс несколько раз на сумму до ${ps.max} ${ps.currency} и повторите заказ`);
					const l = await this.spapi.createPayLink(ps.id, r.addbalance, document.location.origin + '/proxies', {prolong: {id: px.id, period}});
					document.location = l.url;
					return;
				}
				else {
					px.busy = false;
					alert('Что-то пошло не так');
				}
			} catch(e) {
				px.busy = false;
				alert('Что-то пошло не так');
				console.error(e);
			}
		},
		getProxies(param) {
			this.spapi.getProxies(Object.assign({orders: true}, param || {})).then(r => {
				if(!this.orders) {
					this.orders = r.orders;
				} else {
					r.orders.forEach(el => {
						const ex = this.orders.find(t => t.id == el.id);
						if(!ex) this.orders.push(el);
					});
				}
				r.proxies.forEach(el => {
					Object.assign(el, {order: this.orders.find(o => o.id == el.oid) || null});
				});
				if(!this.proxies) {
					this.proxies = r.proxies;
				} else {
					r.proxies.forEach(el => {
						const ex = this.proxies.find(t => t.id == el.id);
						if(ex && ex.uts < el.uts) Object.assign(ex, el);
						else if(!ex) this.proxies.push(el);
					});
				}
			}).catch(e => {
				console.error(e);
			});
		},
		onProxy(d) {
			this.getProxies({id: d.pid, oid: d.oid});
		}
	},
	created() {
		if(!this.glob.profile) {
			this.$router.push('/auth');
			return;
		}
		this.spapi.on('proxy', this.onProxy);
		this.getProxies();
		this.loaded = true;
	},
	beforeDestroy() {
		this.spapi.off(this.onProxy);
	}
}
</script>
