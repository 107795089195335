<template>
	<div v-if="loaded">
		<div>
			Заказы:
			<table>
				<tr>
					<th>ID/TID</th>
					<th>Параметры</th>
					<th>Количество</th>
					<th>Стоимость</th>
					<th>Статус</th>
					<th>Дата</th>
				</tr>
				<tr v-if="!orders"><td colspan="6">Загрузка</td></tr>
				<tr v-else-if="!orders.length"><td colspan="6">Нет заказов</td></tr>
				<template v-else>
					<tr v-for="(o, i) in orderss" :key="'o_' + i">
						<td><b>{{o.xoid}}</b>/{{o.tid}}</td>
						<td>
							<div class="fields">
								<span title="Tarif">{{getName('group', o.group_id)}}</span>
								<span title="DC">{{getName('group', o.dc)}}</span>
								<span v-for="(v, k) in o.select" :key="'o_' + i + '_s_' + k" :title="k">{{getName(k, v)}}</span>
								<span v-for="(v, k) in o.opt" :key="'o_' + i + '_o_' + k" :title="k">{{getName(k, v)}}</span>
							</div>
						</td>
						<td><b>{{o.num_ordered}}</b>/{{o.num}}</td>
						<td><b>{{parseFloat(o.price).toFixed(2)}}</b> ({{parseFloat(o.price_one).toFixed(2)}} x {{o.num}}) {{o.currency}}</td>
						<td>{{o.status}}</td>
						<td>{{new Date(o.ts_order).toISOString()}}</td>
					</tr>
				</template>
			</table>
		</div>
	</div>
</template>

<script>

export default {
	components: { },
	data: () => ({
		loaded: false,
		orders: null
	}),
	computed: {
		orderss() {
			return this.orders ? this.orders.slice().sort((a, b) => b.id - a.id) : [];
		}
	},
	methods: {
		getName(k, v) {
			return this.spapi.getDictName(k, v);
		},
		getOrders(id) {
			this.spapi.getOrders(id ? {id} : null).then(r => {
				if(!this.orders) {
					this.orders = r.orders;
				} else {
					r.orders.forEach(el => {
						const ex = this.orders.find(t => t.id == el.id);
						if(ex && ex.uts < el.uts) Object.assign(ex, el);
						else if(!ex) this.orders.push(el);
					});
				}
			}).catch(e => {
				console.error(e);
			});
		},
		onOrder(d) {
			this.getOrders(d.oid);
		}
	},
	created() {
		if(!this.glob.profile) {
			this.$router.push('/auth');
			return;
		}
		this.spapi.on('order', this.onOrder);
		this.getOrders();
		this.loaded = true;
	},
	beforeDestroy() {
		this.spapi.off('order', this.onOrder);
	}
}
</script>
