<template>
	<div>
		<nav>
			<router-link to="/">Покупка</router-link>
			<router-link to="/opt">Опт</router-link>
			<template v-if="glob.profile">
				<router-link to="/orders">Мои заказы</router-link>
				<router-link to="/proxies">Мои прокси</router-link>
				<router-link to="/balance">Баланс [ {{parseFloat(glob.profile.balance[glob.profile.currency]).toFixed(2)}} {{glob.profile.currency}} ]</router-link>
				<router-link to="/partners">Партнерка [ {{glob.profile.partner_regs}} ]</router-link>
				<router-link to="/account">Аккаунт</router-link>
				<router-link to="/support">Поддержка [ {{glob.profile.support_unread}} ]</router-link>
				<router-link to="/logout">Выход</router-link>
			</template>
			<template v-else>
				<router-link to="/feedback">Поддержка</router-link>
				<router-link to="/auth">Регистрация / Вход</router-link>
			</template>
		</nav>
		<nav v-if="glob.langs">
			<a v-for="l in glob.langs" :key="'l_' + l" :href="'?lang=' + l" :class="{'router-link-exact-active': glob.lang == l}" @click.prevent="setLang(l)">{{l}}</a>
		</nav>
		<nav v-if="glob.currencies">
			<a v-for="l in glob.currencies" :key="'c_' + l" :href="'?currency=' + l" :class="{'router-link-exact-active': glob.currency == l}" @click.prevent="setCurrency(l)">{{l}}</a>
		</nav>
	</div>
</template>

<script>
export default {
	methods: {
		setLang(lang) {
			this.spapi.setLang(lang).catch(e => {
				console.error(e);
				alert('Какая-то ошибка. Повторите запрос позднее.');
			});
		},
		setCurrency(curr) {
			this.spapi.setCurrency(curr).catch(e => {
				console.error(e);
				alert('Какая-то ошибка. Повторите запрос позднее.');
			});
		}
	}
}
</script>
