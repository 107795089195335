<template>
	<!-- 
		Обновление для реализации обязательных/дополнительных полей.
		Устарели и в дальнейшем пропадут из выдачи - SPSAPI.data.groups[].select и SPSAPI.data.groups[].dc[].select
		Ранее в g.select (SPSAPI.data.groups[].select) был объект { ключ поля: true - обязательное / false - дополнительное поле, ... }.
		Теперь вместо этого массив g.sel (SPSAPI.data.groups[].sel) с ключами доступных полей, и отдельно массив g.req (SPSAPI.data.groups[].req) - с ключами обязательных полей, 
		который так же включает признаки обязательных/дополнительных полей для opt.* (опции), period (период), num (количество), dc (сервер/датацентр).
		Пример проверки: g.req.includes('country' || 'proto' || 'num' || 'period' || ...) - true - обязательное поле, false - дополнительное.
	-->
	<div v-if="g">
		<form action="#" @submit.prevent="order()" v-if="allnum">
			<div><b>{{getDictName('group', g.id)}}</b> / {{allnum}} / {{arts.length}}</div>
			<div v-if="details.length">
				<span v-for="(d, i) in details" :key="'det_' + i"><span v-if="i > 0"> / </span>{{d}}</span>
			</div>
			<template v-for="(v, k) in g.sel">
				<div :key="'sel_' + k">
					<span v-if="g.req.includes(v)" title="Обязательно">*</span>{{v}}: 
					<select v-model="sel[v]" :title="v">
						<option value="">{{g.req.includes(v) ? 'Выберите' : 'Любое'}}</option>
						<option v-for="s in getVars(v)" :key="'sel_' + k + '_' + s" :value="s">{{s ? getDictName(v, s) : '-'}}</option>
					</select> <a v-if="!!sel[v]" href="#" @click.prevent="sel[k] = ''">x</a>
				</div>
			</template>
			<template v-for="(v, k) in opts">
				<div :key="'opt_' + k">
					<span v-if="g.req.includes(k)" title="Обязательно">*</span>{{k}}: 
					<select v-model="opt[k]" :title="k">
						<option v-for="s in v" :key="'opt_' + k + '_' + s" :value="s">{{s ? getDictName(k, s) : '-'}}</option>
					</select>
				</div>
			</template>
			<div>
				<span v-if="g.req.includes('period')" title="Обязательно">*</span>Период: <select v-model="period" title="Period">
					<option value="">-</option>
					<option v-for="p in periods" :key="'period_' + p" :value="p">{{p >= 1 ? p + ' дни' : parseInt(p * 100, 10) + ' часы'}}</option>
				</select> <a v-if="!!period" href="#" @click.prevent="period = ''">x</a>
			</div>
			<div>
				<span v-if="g.req.includes('num')" title="Обязательно">*</span>Количество: <input type="number" v-model="q" :min="g.min || 1" step="1" :max="g.max || Infinity"/>
			</div>
			<div v-if="dc_sel">
				<span v-if="g.req.includes('dc')" title="Обязательно">*</span>Датацентр: <select v-model="dc" title="Server">
					<option value="">Выберите</option>
					<option v-for="gg in adc" :key="'dc_' + gg.id" :value="gg.id">{{getDictName('group', gg.id)}}</option>
				</select> <a v-if="!!dc" href="#" @click.prevent="dc = ''">x</a>
			</div>
			<div>Доступно: {{num}}</div>
			<div>Цена: <span style="text-decoration: line-through" v-if="price && price < priceorig">{{parseFloat(priceorig).toFixed(2)}}</span> {{price ? parseFloat(fullprice).toFixed(2) + ' ' + curr + ' (' + parseFloat(price).toFixed(2) + '/шт)' : '-'}}</div>
			<div v-if="!authed"><input type="email" required placeholder="E-Mail" title="E-Mail" v-model="email"/></div>
			<div><input type="text" v-model="f_coupon" title="Купон" placeholder="Купон"><button type="button" @click.prevent="couponCheck" :disabled="!f_coupon">Применить</button> - {{discount ? 'Скидка ' + discount + '%' : 'Нет скидки'}}</div>
			<div><button type="submit" :disabled="!price || q > num">Заказать</button></div>
			<div v-if="discounts.length">
				<span v-for="(d, i) in discounts" :key="'disc_' + i"><span v-if="i > 0"> / </span>От <b>{{d.num}}</b> шт - <b>{{d.price}}</b> {{curr}}</span>
			</div>
		</form>
		<div v-else>На данный момент ничего не доступно</div>
	</div>
</template>

<script>
export default {
	props: {
		group: String,
		onlyavailable: {
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		discount: 0,
		coupon: null,
		f_coupon: '',
		dc_sel: true,
		email: '',
		psid: null,
		dc: '',
		q: 1,
		period: '',
		sel: {
		},
		opt: {
		},
	}),
	computed: {
		details() {
			return this.spapi.getDictData('group', this.group, 'details') || [];
		},
		authed() {
			return !!this.glob.profile;
		},
		g() {
			return this.glob.groups.find(el => el.id == this.group || el.txtid == this.group) || null;
		},
		curr() { // Валюта
			return this.glob.currency;
		},
		arts_old_() { // Отфильтрованные позиции
			return this.g.articles.filter(art => {
				if(this.onlyavailable && !art.num) return false;
				if(this.dc && art.dc != this.dc) return false;
				if(this.period && !this.g.dc.find(el => el.id == art.dc && el.periods.includes(this.period))) return false;
				for(const k in this.sel) {
					if(this.sel[k] && this.sel[k] != art[k]) return false;
				}
				return true;
			});
		},
		arts() { // Отфильтрованные позиции
			const params = {}
			if(this.dc) params.dc = this.dc;
			if(this.period) params.period = this.period;
			for(const k in this.sel) {
				if(this.sel[k]) params[k] = this.sel[k];
			}
			const r = this.spapi.filterDataGroup(this.g, params);
			if(!r) return [];
			return this.onlyavailable ? r.articles.filter(el => el.num > 0) : r.articles;
		},
		allnum_old() { // Остаток всех имеющихся позиций - старая версия
			return this.g.articles.reduce((n, el) => n += (el.num || 0), 0);
		},
		num_old() { // Остаток выбранных позиций
			if(!this.dc || !this.g.dc.find(el => el.id == this.dc)) return 0; 
			for(const k of this.g.sel) {
				if(this.g.req.includes(k) && !this.sel[k]) return 0;
			}
			return this.arts.reduce((n, el) => n += (el.num || 0), 0);
		},
		allnum() { // Остаток всех имеющихся позиций
			return this.spapi.calcAvailables(this.g.articles);
		},
		num() { // Остаток выбранных позиций
			if(!this.dc || !this.g.dc.find(el => el.id == this.dc)) return 0; 
			for(const k of this.g.sel) {
				if(this.g.req.includes(k) && !this.sel[k]) return 0;
			}
			return this.spapi.calcAvailables(this.arts);
		},
		price_raw() { // Прайс за один прокси
			if(!this.dc || !this.period || !this.num || !this.arts.length) return {};
			return this.spapi.calcPrice(this.period, this.q, this.arts, this.discount, true);
		},
		discounts() {
			if(!this.price_raw.price) return [];
			return this.spapi.getPeriodDiscounts(this.period, this.arts, this.discount);
		},
		price() {
			return this.price_raw.price_one || 0;
		},
		priceorig() {
			return this.price_raw.orig_price || 0;
		},
		fullprice() { // Прайс за все прокси
			return this.price_raw.price || 0;
		},
		adc() { // Отфильтрованные датацентры
			const dcs = [];
			this.arts.forEach(el => {
				if(!dcs.includes(el.dc)) dcs.push(el.dc); // Выбираем датацентр только если в нем есть товары с выбранными параметрами
			});
			return dcs.map(el => this.g.dc.find(g => g.id == el)).filter(dc => { // Выбираем датацентр только если он поддерживает выбранные опции (opt)
				for(const k in dc.opt) {
					if(this.opt[k] && !dc.opt[k].includes(this.opt[k])) return false;
				}
				return true;
			});
		},
		periods() { // Отфильтрованные периоды
			const out = [];
			this.adc.forEach(dc => {
				dc.periods.forEach(per => {
					if(!out.includes(per)) out.push(per);
				});
			});
			return out.sort((a, b) => a - b); // Сортируем
		},
		opts() { // Отфильтрованные опции
			const out = {}
			this.adc.forEach(dc => {
				for(const k in dc.opt) {
					if(!out[k]) out[k] = [];
					dc.opt[k].forEach(o => {
						if(!out[k].includes(o)) out[k].push(o); // Выбираем только те опции, которые поддерживаются доступными датацентрами
					});
				}
			});
			return out;
		},
		payin() {
			return this.glob.payin.filter(el => el.active && el.currency == this.glob.currency);
		}
	},
	watch: {
		email() {
			this.coupon = null;
			this.discount = 0;
		},
		'glob.currency'() {
			this.psid = this.payin[0].id;
		}
	},
	methods: {
		async couponCheck() {
			if(!this.price) return;
			if(!this.f_coupon) return;
			if(!this.authed && !this.email) return alert('Введите e-mail');
			if(this.q > this.num) return alert('Не хватает нужного количества 1');
			const dc = this.g.dc.find(el => el.id == this.dc);
			if(!dc) return;
			const q = {
				num: this.q,
				period: this.period,
				dc: this.dc,
				price: this.fullprice,
				coupon: this.f_coupon,
				test: true
			}
			for(const k of this.g.sel) {
				if(this.g.req.includes(k) && !this.arts.find(el => el[k] == (this.sel[k] || null))) return alert('Выбраны не все параметры');
				if(this.sel[k]) q[k] = this.sel[k] || null;
			}
			for(const k in dc.opt) {
				if(!dc.opt[k].includes(this.opt[k])) return alert('Выбраны не все параметры');
				if(this.opt[k]) q[k] = this.opt[k] || null;
			}
			this.spapi.buy(q).then(r => {
				if(r.coupon) {
					this.coupon = this.f_coupon = r.coupon;
					this.discount = r.coupon_discount;
				} else {
					this.f_coupon = this.coupon || '';
					alert('Купон недействителен');
				}
			}).catch(e => {
				alert('Что-то пошло не так');
				console.error(e);
			});
		},
		getDictName(g, k) { // Получить из словаря название элемента на текущем языке
			return this.spapi.getDictName(g, k);
		},
		getVars(field) { // Отфильтрованные значения параметров
			const els = [];
			this.arts.forEach(art => {
				if(art[field] && !els.includes(art[field])) els.push(art[field]);
			});
			return els;
		},
		async order() {
			try {
				if(!this.price) return;
				if(!this.authed && !this.email) return alert('Введите E-Mail!');
				if(this.q > this.num) return alert('Не хватает нужного количества 1');
				const dc = this.g.dc.find(el => el.id == this.dc);
				if(!dc) return;
				const q = {
					num: this.q,
					period: this.period,
					dc: this.dc,
					price: this.fullprice,
					coupon: this.coupon || null
				}
				for(const k of this.g.sel) {
					if(this.g.req.includes(k) && !this.arts.find(el => el[k] == (this.sel[k] || null))) return alert('Выбраны не все параметры');
					if(this.sel[k]) q[k] = this.sel[k] || null;
				}
				for(const k in dc.opt) {
					if(!dc.opt[k].includes(this.opt[k])) return alert('Выбраны не все параметры');
					if(this.opt[k]) q[k] = this.opt[k] || null;
				}
				const r = await this.spapi.buy(q);
				if(r.status == 'nomoney') {
					const ps = this.glob.payin.find(el => el.id == this.psid);
					if(r.addbalance < ps.min) return alert(`Слишком мелкая сумма для пополнения, пополните баланс на сумму от ${ps.min} ${ps.currency} и повторите заказ`);
					else if(r.addbalance > ps.max) return alert(`Слишком большая сумма для одноразового пополнения, пополните баланс несколько раз на сумму до ${ps.max} ${ps.currency} и повторите заказ`);
					let l;
					if(!this.authed) {
						const checkuser = await this.spapi.login(this.email, null, {reg: 2});
						if(checkuser.status == 'newuser') {
							l = await this.spapi.createPayLink(this.psid, r.addbalance, document.location.origin + '/orders', {order: q});
						} else {
							l = await this.spapi.createPayLink(this.psid, r.addbalance, document.location.origin + '/auth?' + new URLSearchParams({email: this.email}).toString(), {order: q, email: this.email});
						}
					} else {
						l = await this.spapi.createPayLink(this.psid, r.addbalance, document.location.origin + '/orders', {order: q});
					}
					document.location = l.url;
					return;
				}
				if(r.status == 'unavailable') return alert('Не хватает нужного количества 2');
				if(r.status == 'ok') {
					this.$router.push('/orders');
					return alert('Заказ оформлен');
				}
				throw new Error('Another error');
			} catch(e) {
				alert('Что-то пошло не так');
				console.error(e);
			}
		}
	},
	created() {
		this.psid = this.payin[0].id;
		if(this.g.dc.length == 1) {
			this.dc_sel = false;
			this.dc = this.g.dc[0].id;
		} 
		else if(this.g.def.dc) {
			this.dc = this.g.def.dc;
		}
		if(this.g.def.num) {
			this.q = this.g.def.num;
		}
		if(this.g.def.period) {
			this.period = this.g.def.period;
		}
		else {
			this.period = this.periods[0];
		}
		this.g.sel.forEach(k => {
			this.$set(this.sel, k, this.g.def[k] || '');
		});
		this.g.dc.forEach(dc => {
			Object.keys(dc.opt).forEach(k => {
				this.$set(this.opt, k, this.g.def[k] || dc.opt[k][0] || '');
			});
		});
	}
}
</script>
