<!--
Для отлова изменения profile.support_unread (количество непрочитанных сообщений):
> SPSAPI.on('profile_add', r => { if('support_unread' in r) { /* Число изменилось */ } });
-->

<template>
	<div v-if="loaded" class="home">
		<div>
			<form @submit.prevent="send">
				<div>
					<div v-for="(f, i) in files" :key="'f_' + i">
						<div v-if="f.mime.startsWith('image/')" style="width: 100px; height: 100px"><img style="max-width: 100%; max-height: 100%;" :src="['data:', f.mime, ';base64,', f.data].join('')"/></div>
						<div>{{f.name}} / {{f.mime || 'application/octet-stream'}} / {{f.size}} / <a href="#" @click.prevent="fileDel(f)">Del</a></div>
						<hr/>
					</div>
				</div>
				<input type="file" multiple @change="fileAdd"/>
				<br/>
				<br/>
				<textarea rows="4" style="width: 100%" v-model="msg" placeholder="Сообщение"></textarea>
				<br/>
				<br/>
				<button type="submit">Send</button>
				<br/><br/>
				<div v-if="status == 'open'"><button type="button" @click.prevent="close">Close</button></div>
				<div v-else>Dialog inactive. For activate send message.</div>
			</form>
		</div>
		<hr/>
		<div v-if="messages && messages.length">
			<div v-for="(m, i) in smessages" :key="'m_' + i">
				<div><b>{{getAuthorName(m)}}</b> {{new Date(m.ts).toISOString()}}</div>
				<div v-if="m.files && m.files.length">
					<div v-for="(f, ii) in m.files" :key="'m_' + i + '_f_' + ii">
						<div v-if="f.mime.startsWith('image/')" style="width: 100px; height: 100px"><img style="max-width: 100%; max-height: 100%;" :src="['data:', f.mime, ';base64,', f.data].join('')"/></div>
						<div v-else><a :download="f.name" :href="['data:application/octet-stream;base64,', f.data].join('')" :title="f.size">{{f.name}}</a></div>
					</div>
				</div>
				<div v-if="m.msg" v-html="uhtml(m.msg)"></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data: () => ({
		msg: '',
		files: [],
		loaded: false,
		managers: null,
		status: null,
		messages: null,
	}),
	computed: {
		smessages() {
			return (this.messages || []).slice().sort((a, b) => b.id - a.id);
		}
	},
	methods: {
		close() {
			this.spapi.supportDialogClose().then(r => {
				this.status = r.status;
			}).catch(e => {
				console.error(e);
			});
		},
		getAuthorName(m) {
			if(m.utype == 'user') return 'Вы';
			else if(m.utype == 'notice') return 'Оповещение';
			const ex = this.managers.find(el => el.id == m.uid);
			return ex && ex.name || 'Manager';
		},
		uhtml(text) {
			return this.spapi.supportTextToHtml(text);
		},
		newMessage(m) {
			const ex = this.messages.find(el => el.id === m.id);
			if(ex) Object.assign(ex, m);
			else this.messages.push(m);
		},
		fileDel(f) {
			this.files = this.files.filter(el => el !== f);
		},
		async fileAdd(ev) {
			const files = ev.target.files;
			for (const f of files) {
				const ex = this.files.find(el => el.size == f.size && el.mime == f.type && el.name == f.name);
				if(!ex) {
					const dataURL = await new Promise((pres, prej) => {
						const reader = new FileReader();
						reader.readAsDataURL(f);
						reader.onload = () => {
							pres(reader.result);
						}
						reader.onerror = (e) => {
							prej(e);
						}
					});
					const data = dataURL.slice(dataURL.indexOf(';base64,') + 8);
					this.files.push({
						size: f.size,
						name: f.name,
						mime: f.type,
						data
					});
				}
			}
			ev.target.value = '';
		},
		onNewMessage(d) {
			this.spapi.supportDialog({id: d.id}).then(r => {
				this.status = r.status;
				r.messages.forEach(this.newMessage);
			}).catch(e => {
				console.error(e);
			})
		},
		send() {
			if(!this.msg && !this.files.length) return;
			this.spapi.supportSend(this.msg, this.files).then(r => {
				this.newMessage(r.message);
				this.status = r.status;
				this.msg = '';
				this.files = [];
			}).catch(e => {
				console.error(e);
				alert('Какая-то ошибка. Повторите запрос позднее.');
			})
		},
		load() {
			Promise.all([
				this.spapi.supportManagers(),
				this.spapi.supportDialog()
			]).then(r => {
				this.managers = r[0];
				this.messages = r[1].messages;
				this.status = r[1].status;
				this.loaded = true;
			}).catch(e => {
				console.error(e);
				alert('Какая-то ошибка. Повторите запрос позднее.');
			});
		},
	},
	created() {
		if(!this.glob.profile) {
			this.$router.push('/auth');
			return;
		}
		this.spapi.on('support_message', this.onNewMessage);
		this.load();
	},
	beforeDestroy() {
		this.spapi.off('support_message', this.onNewMessage);
	}
}
</script>
