<template>
	<div class="home">
		<div v-for="(g, i) in groups" :key="'g' + g.id">
			<hr v-if="i != 0"/>
			<articles-box :group="g.txtid" :onlyavailable="false"/>
		</div>
	</div>
</template>

<script>

import ArticlesBox from './ArticlesBox.vue';

export default {
	components: { ArticlesBox },
	data: () => ({
	}),
	computed: {
		groups() {
			return this.glob.groups.filter(el => el.tags.includes('main'));
		}
	},
	created() {
	}
}
</script>
