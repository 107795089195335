<template>
	<div v-if="loaded" class="home">
		<form @submit.prevent="changeMail">
			Мыло / Логин:
			<input type="email" v-model="fmail.email" placeholder="Email"/>
			<button type="submit" :disabled="fmail.email == fmail.old">Сменить</button>
		</form>
		<hr>
		<form @submit.prevent="changePass">
			Смена пароля:
			<input type="password" v-model="fpass.pass" placeholder="Новый пароль"/>
			<input type="password" v-model="fpass.pass2" placeholder="Повтор"/>
			<button type="submit">Сменить</button>
		</form>
		<hr>
		<div v-if="glob.profile.tgbot.id">
			<div>Телега подключена к аккаунту: <b>{{glob.profile.tgbot.account || 'Скрыто'}}</b> / <a href="#" @click.prevent="tgbotDel">Отключить</a></div>
			<hr/>
			<div>2FA через телегу: <a href="#" @click.prevent="tgbotAuth">{{glob.profile.tgbot.auth ? 'Включено' : 'Выключено'}}</a></div>
		</div>
		<div v-else>Для подключения телеги отправьте боту <a :href="'https://t.me/' + glob.tgbot_name + '?start=' + encodeURIComponent(glob.profile.tgbot.connectcode)" target="_blank">{{glob.tgbot_name}}</a> сообщение <b>/start {{glob.profile.tgbot.connectcode}}</b></div>
		<hr>
		<form action="#" @submit.prevent>
			<table>
				<tr><td>На мыло</td><td>В телегу</td><td></td></tr>
				<tr><td><input type="checkbox" v-model="cb.mail.prolong"/></td><td><input type="checkbox" v-model="cb.tg.prolong"/></td><td>Получать письма о необходимости продления прокси</td></tr>
				<tr><td><input type="checkbox" v-model="cb.mail.autoprolong"/></td><td><input type="checkbox" v-model="cb.tg.autoprolong"/></td><td>Автопродление</td></tr>
				<tr><td><input type="checkbox" v-model="cb.mail.news"/></td><td><input type="checkbox" v-model="cb.tg.news"/></td><td>Получать письма (рассылку) о новостях сервиса, купоны, акции</td></tr>
				<tr><td><input type="checkbox" v-model="cb.mail.account"/></td><td><input type="checkbox" v-model="cb.tg.account"/></td><td>Получать письма о входах и изменениях в аккаунте</td></tr>
			</table>
		</form>
	</div>
</template>

<script>
export default {
	data: () => ({
		loaded: false,
		cb: {
			mail: {
				prolong: null,
				autoprolong: null,
				news: null,
				account: null,
			},
			tg: {
				prolong: null,
				autoprolong: null,
				news: null,
				account: null,
			}
		},
		fmail: {
			old: '',
			email: '',
		},
		fpass: {
			pass: '',
			pass2: '',
		}
	}),
	watch: {
		'cb.mail.prolong'(val, old) {
			if([old, val].includes(null)) return;
			this.setReport('email', 'prolong', val);
		},
		'cb.mail.autoprolong'(val, old) {
			if([old, val].includes(null)) return;
			this.setReport('email', 'autoprolong', val);
		},
		'cb.mail.news'(val, old) {
			if([old, val].includes(null)) return;
			this.setReport('email', 'news', val);
		},
		'cb.mail.account'(val, old) {
			if([old, val].includes(null)) return;
			this.setReport('email', 'account', val);
		},
		'cb.tg.prolong'(val, old) {
			if([old, val].includes(null)) return;
			this.setReport('tgbot', 'prolong', val);
		},
		'cb.tg.autoprolong'(val, old) {
			if([old, val].includes(null)) return;
			this.setReport('tgbot', 'autoprolong', val);
		},
		'cb.tg.news'(val, old) {
			if([old, val].includes(null)) return;
			this.setReport('tgbot', 'news', val);
		},
		'cb.tg.account'(val, old) {
			if([old, val].includes(null)) return;
			this.setReport('tgbot', 'account', val);
		},
	},
	methods: {
		tgbotAuth() {
			this.spapi.tgbotAuth(!this.glob.profile.tgbot.auth).catch(e => {
				console.error(e);
				alert('Какая-то ошибка. Повторите запрос позднее.');
			});
		},
		tgbotDel() {
			this.spapi.tgbotDel().then(() => {
				this.reloadReports();
			}).catch(e => {
				console.error(e);
				alert('Какая-то ошибка. Повторите запрос позднее.');
			});
		},
		setReport(to, key, val) {
			this.spapi.setReport(to, key, val).catch(e => {
				console.error(e);
				alert('Какая-то ошибка. Повторите запрос позднее.');
			});
		},
		changeMail() {
			const mail = this.fmail.email.trim();
			if(!mail) return alert('Введите мыло');
			if(!/^\S+@\S+\.\S+$/.test(mail)) return alert('Неправильный формат');
			this.spapi.changeMail(mail).then(r => {
				if(r.status == 'ok') {
					this.fmail.old = r.email;
					alert(`Мыло изменено на ${r.email}`);
				} else {
					alert('Не сработало');
				}
			}).catch(e => {
				console.error(e);
				alert('Какая-то ошибка. Повторите запрос позднее.');
			});
		},
		changePass() {
			const pass = this.fpass.pass.trim();
			if(!pass) return alert('Введите пароль');
			if(pass.length < 3) return alert('Слишком короткий');
			if(pass != this.fpass.pass2) return alert('Пароли не совпадают');
			this.spapi.changePass(pass).then(r => {
				if(r.status == 'ok') {
					alert(`Пароль изменен на ${r.pass}`);
					this.fpass.pass = '';
					this.fpass.pass2 = '';
				} else {
					alert('Не сработало');
				}
			}).catch(e => {
				console.error(e);
				alert('Какая-то ошибка. Повторите запрос позднее.');
			});
		},
		reloadReports() {
			const reps = Object.keys(this.cb.mail);
			reps.forEach(rep => {
				this.cb.mail[rep] = null;
				this.cb.mail[rep] = (this.glob.profile.email_reports || []).includes(rep);
				this.cb.tg[rep] = null;
				this.cb.tg[rep] = (this.glob.profile.tgbot.reports || []).includes(rep);
			});

		}
	},
	created() {
		if(!this.glob.profile) {
			this.$router.push('/auth');
			return;
		}
		this.fmail.email = this.glob.profile.email;
		this.fmail.old = this.glob.profile.email;
		this.reloadReports();
		this.loaded = true;
	}
}
</script>
